<template>
  <div>
        <el-card >
          <el-table :data="interimAPList" >
            <el-table-column prop="id" label="ID" width="50"></el-table-column>
            <el-table-column prop="invPDF" label="invPDF" align="center"></el-table-column>
            <el-table-column prop="bookingID" label="bookingID" align="center"></el-table-column>
            <el-table-column prop="invType" label="invType" align="center"></el-table-column>
            <el-table-column prop="exRate" label="exRate" align="center"></el-table-column>
            <el-table-column prop="reportingDate" label="reportingDate" align="center"></el-table-column>
            <el-table-column prop="finalAmount" label="finalAmount" align="center"></el-table-column>
            <el-table-column prop="invNum" label="invNum" align="center"></el-table-column>
            <el-table-column prop="payer" label="payer" align="center"></el-table-column>

          </el-table>
        </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
export default defineComponent({
  name: 'testPage',
  setup() {
    const interimAPList = ref()
    onMounted(() => {
      const reqURL = process.env.VUE_APP_BASE + '/booking/Surpath-28/invoice'
      axios.get(reqURL).then((response) => {
        interimAPList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    })
    return { interimAPList }
  }
})
</script>
